import axios from "axios";
import { LocalizationContext } from "context/LangChange";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const ClientBrandProducts = () => {
  let { isLang } = useContext(LocalizationContext);
  let { id } = useParams();
  let { t } = useTranslation();
  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);
  const [Data1, setData1] = useState(null);

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{t("brandproducts")}</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          className="w-full p-inputtext-sm"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder={t("search_placeholder")}
        />
      </span>
    </div>
  );

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/clients/brandproducts`;
    let data = await axios.post(
      url,
      { IDClient: id },
      {
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + localStorage.getItem("tokenBetter"),
        },
      }
    );
    setData(data?.data?.Response?.ClientBrandProducts);
    setData1(data?.data?.Response);
  };
  useEffect(() => {
    fetchData();
  }, [isLang]);

  const [editingRow, setEditingRow] = useState(null);

  const statusBodyTemplate = (rowData) => {
    return editingRow === rowData?.ClientBrandProductStatus ? (
      <></>
    ) : (
      <Tag
        className="px-3"
        value={`${rowData?.ClientBrandProductStatus === "Used"
          ? t("USED")
          : rowData?.ClientBrandProductStatus === "PENDING"
            ? t("pending")
            : rowData?.ClientBrandProductStatus === "Not Used"
              ? t("NOUSED")
              : null
          }`}
        severity={getSeverity(rowData.ClientBrandProductStatus)}
        rounded
      />
    );
  };
  const getSeverity = (status) => {
    switch (status) {
      case "Used":
        return "success";

      case "PENDING":
        return "warning";

      case "CANCELLED":
        return "danger";

      case "Not Used":
        return "danger";

      case "USED":
        return "info";

      case "":
        return "info";
    }
  };

  return (
    <div>
      <div className="card mt-5">
        <div className="grid  p-2 ">
          <div className="lg:col-3 md:col-12 sm:col-12 mt-2   ">
            <div className="client_summary border-round shadow-3 py-3  px-4 flex flex-row  gap-4 align-items-center">
              <div className="icons">
                <i className="pi pi-money-bill"></i>
              </div>
              <div className="content flex flex-column">
                <span className="header user">{t("Money")}</span>
                <span className="number">
                  {Data1?.MoneySaved ? Data1?.MoneySaved : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="lg:col-3 md:col-12 sm:col-12 mt-2   ">
            <div className="client_summary Sales_summary2 border-round shadow-3 py-3  px-4 flex flex-row  gap-4 align-items-center">
              <div className="icons">
                <i className="pi pi-users"></i>
              </div>
              <div className="content flex flex-column">
                <span className="header">{t("Visits")}</span>
                <span className="number">
                  {Data1?.ProductNumber ? Data1?.ProductNumber : 0}
                </span>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          className="custom-datatable"
          scrollable
          scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          size="normal"
          header={header}
        >
          <Column
            field="BrandProductPrice"
            header={isLang === "en" ? "Product Price" : "قيمه المنتج"}
            style={{ textAlign: "center" }}
          ></Column>
          <Column
            field="ProductDiscount"  // تم تغيير الحقل ليعكس الخصم بدلاً من السعر
            header={isLang === "en" ? "Discount value" : "قيمة الخصم"}
            style={{ textAlign: "center" }}
          ></Column>
          <Column
            field="ClientBrandProductSerial"
            header={t("Serial")}
            style={{ textAlign: "center" }}
          ></Column>
          <Column
            field="BrandProductTitle"
            header={t("title")}
            style={{ maxWidth: "fitContent", textAlign: "center" }}
          ></Column>
          <Column
            field="BrandName"
            header={t("brand_name")}
            style={{ maxWidth: "fitContent", textAlign: "center" }}
          ></Column>
          <Column
            field="ClientBrandProductStatus"
            header={t("status")}
            body={statusBodyTemplate}
            style={{ maxWidth: "fitContent", textAlign: "center" }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default ClientBrandProducts;
