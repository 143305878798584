import React, { useState, useEffect } from 'react';
import { Table, Pagination, Spin, Alert } from 'antd';
import axios from 'axios';
import { format } from 'date-fns';

const ContactMessagess = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        // جلب البيانات من الـ API
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.post('https://backend.nfcads.com/api/admin/contact-messages', {}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                    },
                });
                // فرضاً أن البيانات موجودة في response.data
                console.log(response.data);

                setData(response.data);
                setTotal(response.data.length); // يمكنك تعديلها حسب استجابة الـ API
            } catch (err) {
                setError('Something went wrong while fetching data.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const columns = [
     
        {
            title: 'Full Name',
            dataIndex: 'full_name',
            key: 'full_name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => format(new Date(text), 'dd MMMM yyyy - hh:mm a'), // تنسيق التاريخ والساعة
        },
    ];

    // Pagination
    const onChange = (page, pageSize) => {
        setCurrent(page);
        setPageSize(pageSize);
    };

    const paginatedData = data.slice((current - 1) * pageSize, current * pageSize);

    return (
        <div className='mt-7 px-3'>
            {error && <Alert message={error} type="error" />}
            {loading ? (
                <div className="flex justify-content-center align-items-center mt-7 pt-7">

                    <Spin tip="Loading..." size='large' />
                </div>
            ) : (
                <>
                    <Table
                        dataSource={paginatedData}
                        columns={columns}
                        pagination={false}
                        rowKey="id"
                    />
                    <Pagination
                        current={current}
                        pageSize={pageSize}
                        total={total}
                        onChange={onChange}
                    />
                </>
            )}
        </div>
    );
};

export default ContactMessagess;
